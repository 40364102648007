<!--合同回款明细表-->
<template>
    <div class="sales-detail-report">
        <reportTable
            code="ContractPaymentCollectionDetails"
            :query="query"
            :percentage="percentage"
            @generateComplete="generateComplete"
            @exportTable="exportTable"
            @showQuery="setBodyHeight"
            custom-export
        >
            <template #queryItems>
                <div class="query-item">
                    <label class="name">场站：</label>
                    <el-select
                        v-model="query.station_ids"
                        placeholder="请选择"
                        clearable
                        multiple
                    >
                        <el-option
                            v-for="item in stationList"
                            :key="item.station_id"
                            :label="item.name"
                            :value="item.station_id"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="query-item">
                    <label class="name">客户名称：</label>
                    <el-input placeholder="请输入" v-model="query.client_name"></el-input>
                </div>
                <div class="query-item">
                    <label class="name">工程名称：</label>
                    <el-input placeholder="请输入" v-model="query.engineering_name"></el-input>
                </div>
                <div class="query-item">
                    <label class="name">回款方式：</label>
                    <el-select
                        v-model="query.payment_collection_method"
                        placeholder="请选择"
                        clearable
                    >
                        <el-option
                            v-for="item in payTypes"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="query-item">
                    <label class="name">业务员：</label>
                    <el-input placeholder="请输入" v-model="query.salesman_name"></el-input>
                </div>
                <div class="query-item">
                    <label class="name">合同编号：</label>
                    <el-input placeholder="请输入" v-model="query.contract_number"></el-input>
                </div>
            </template>
            <template #customTable>
                <u-table
                    ref="plTable"
                    :data="tableList"
                    :height="height"
                    :row-height="40"
                    use-virtual
                    border
                >
                    <u-table-column
                        :label="tableTitle"
                    >
                        <u-table-column
                            v-for="(item,index) in tableHeader"
                            :key="'col'+index"
                            :label="item.label"
                            :prop="item.prop"
                            :width="item.width"
                        >
                        </u-table-column>
                    </u-table-column>
                </u-table>
            </template>
        </reportTable>
    </div>
</template>

<script>
import reportTable from '@/components/reportTable/reportTable.vue';
import { export_json_to_excel } from '@/assets/js/exportExcel/index';
import utils from '@/utils/util';
export default {
    components: { reportTable },
    data() {
        const tableHeader = [
            {
                label: '回款日期',
                prop: 'data_date',
                width: '100',
            },
            {
                label: '合同编号',
                prop: 'contract_number',
                width: '200',
            },
            {
                label: '合同类型',
                prop: 'contract_type_name',
                width: '100',
            },
            {
                label: '客户',
                prop: 'client_name',
                width: '220',
            },
            {
                label: '工程名称',
                prop: 'engineering_name',
                width: '220',
            },
            {
                label: '回款金额',
                prop: 'payment_collection_amount',
                width: '110',
            },
            {
                label: '回款方式',
                prop: 'payment_collection_method_name',
                width: '110',
            },
            {
                label: '承兑号',
                prop: 'acceptance_no',
                width: '110',
            },
            {
                label: '业务员',
                prop: 'salesman_name',
                width: '110',
            },
            {
                label: '站点',
                prop: 'station_name',
                width: '110',
            },
            {
                label: '财务确认时间',
                prop: 'confirm_time',
            },
        ];
        return {
            loading: false,
            tableHeader: tableHeader,
            query: {
                contract_number: '',
                client_name: '',
                engineering_name: '',
                station_ids: [],
                payment_collection_method: '',
                salesman_name: '',
            },
            stationList: [],
            payTypes: [],
            tableTitle: '合同回款明细表',
            tableList: [],
            tableStationList: [],
            totalData: [[]],
            // 分批加载 大小
            limit: 10,
            // 进度条
            percentage: 100,
            queryArr: null,
            height: 0,
        };
    },
    created() {
        this.getStationList();
        this.getPayTypes();
    },
    mounted() {
        let resize = null;
        window.addEventListener('resize', () => {
            resize = this.setBodyHeight(resize);
        });
        this.setBodyHeight(resize);
    },
    methods: {
        setBodyHeight(resize) {
            clearInterval(resize);
            const timer = setTimeout(() => {
                this.height = document.querySelector('.table-box').clientHeight;
                if (this.$refs.virtualTable) {
                    this.$refs.virtualTable.doLayout();
                }
            }, 50);
            return timer;
        },
        // 获取场站列表
        async getStationList() {
            const data = await this.$axios.get('/interfaceApi/baseinfo/stationmanger/get_station_list');
            if (data.length > 0) {
                this.stationList = data;
            }
        },
        // 获取回款方式
        async getPayTypes() {
            const data = await this.$axios.get('/interfaceApi/baseinfo/dictionary_config/getlist?parent_code=601-FK0');
            if (data.length > 0) {
                this.payTypes = data;
            }
        },
        // 报表生成完成
        async generateComplete(params) {
            try {
                this.queryArr = null;
                this.loading = true;
                this.tableList = [];
                this.percentage = 0;
                const startTimes = new Date(params.startTime).getTime();
                const endTimes = new Date(params.endTime).getTime();
                const splitTimes = this.limit * 24 * 60 * 60 * 1000;
                const arr = [];
                for (let times = startTimes; times <= endTimes; times += splitTimes) {
                    const start = utils.timeFormat(new Date(times), 'yyyy-MM-dd');
                    let endms = times + splitTimes;
                    if (endms >= endTimes) {
                        endms = endTimes;
                    }
                    const end = utils.timeFormat(new Date(endms), 'yyyy-MM-dd');
                    if (arr.length > 0 && start === end) {
                        break;
                    }
                    arr.push([start, end]);
                }
                this.queryArr = arr;
                for (const i in arr) {
                    if (this.queryArr !== arr) {
                        return;
                    }
                    const pam = Object.assign( {}, params);
                    pam.startTime = arr[i][0];
                    pam.endTime = arr[i][1];
                    const listUrl = '/interfaceApi/report/payment_collection_details/get_payment_collection_details';
                    const list = await this.$axios.post(listUrl, pam);
                    this.percentage = parseInt((Number(i) + 1) / arr.length * 100, 0);
                    list.forEach(item => {
                        item.data_date = item.data_date.substr(0, 10);
                    });
                    this.tableList.push(...list);
                    if (Number(i) === 0) {
                        this.loading = false;
                    }
                }
                if (this.tableList.length > 0) {
                    this.getTotalData(params);
                }
                this.percentage = 100;
            } catch (e) {
                this.$message.error(e.ErrorCode ? e.ErrorCode.Message : e.message);
            } finally {
                this.percentage = 100;
                this.loading = false;
            }
        },
        // 获取合计数据
        async getTotalData(params) {
            const url = '/interfaceApi/report/payment_collection_details/get_payment_collection_total';
            const res = await this.$axios.post(url, params);
            const data = {};
            this.tableHeader.forEach(item => {
                data[item.prop] = '';
            });
            data.data_date = '合计';
            data.payment_collection_amount = res.payment_collection_amount_total;
            this.tableList.push(data);
        },
        sliceArr(arr, chunkSize) {
            const res = [];
            for (let i = 0; i < arr.length; i += chunkSize) {
                const chunk = arr.slice(i, i + chunkSize);
                res.push(chunk);
            }
            return res;
        },
        exportTable() {
            const data = [];
            const merges = [
                { s: { r: 0, c: 0 }, e: { r: 0, c: this.tableHeader.length - 1 } },
            ];
            const header1 = new Array(this.tableHeader.length).fill('');
            header1[0] = this.tableTitle;
            const header2 = this.tableHeader.map(item => item.label);
            const header = [
                header1,
                header2,
            ];
            this.tableList.forEach(row => {
                const arr = [];
                this.tableHeader.forEach(item => {
                    arr.push(row[item.prop]);
                });
                data.push(arr);
            });
            export_json_to_excel({
                multiHeader: header,
                merges: merges,
                data,
                filename: this.tableTitle,
            });
        },
    },
    destroyed() {
        this.queryArr = null;
    },
};
</script>
<style lang="stylus" scoped>

</style>

